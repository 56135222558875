import React from 'react'
import Title from '../components/Title'
import Layout from '../components/Layout'
import { graphql } from 'gatsby';

const DomainsTemplate = ({data}) => {
  const { documentsYaml: domains } = data
  console.log(domains);
  const blocks = domains.dns.map(({key, name}) => <tr key={name}><td>{name}</td><td><code>{key}</code></td></tr>);
  return (
    <Layout>
      <Title>{domains.heading}</Title>
      <table>
        <tbody>
      {blocks}
        </tbody>
      </table>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    documentsYaml(slug: { eq: $slug }) {
      heading
      dns { name, key }  }
  }
`

export default DomainsTemplate
